import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { capitalizeFirstLetter, clearZero, formatInput } from '../utilities';

const SellerSideMiscellaneous = ({data, setMiscellaneousTotal, className, saving, setSellersMiscellaneous, setMiscOpposites, miscData, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */

    const watchTaxProration = watch("taxProration");
    const watchSecondHalfPriorYearTaxes = watch("secondHalfTaxes");
    const watchAssistanceToBuyer = watch("assistanceToBuyer");
    const watchHomeWarranty = watch("homeWarranty");
    const watchPestInspection = watch("pestInspection");
    const watchHomeownersAssociationFeeCredit = watch("homeOwnersAssociationCreditFromBuyer");
    const watchMiscCost1 = watch("miscellaneousFee1");
    const watchMiscCost2 = watch("miscellaneousFee2");
    const watchMiscCost3 = watch("miscellaneousFee3");
    const watchMiscCost4 = watch("miscellaneousFee4");
    const watchCredit1 = watch("credit1");    
    const watchCredit2 = watch("credit2");
    const watchCredit3 = watch("credit3");
    const watchCredit4 = watch("credit4");
    const watchSellerTotalMiscellaneousInput = watch("sellerTotalMiscellaneous");

    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [changingObj, setChangingObj] = useState(null);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    
    useEffect(() => {
        if (!miscData || (loadedNetSheet && !alreadyLoadedNetSheet)) return;
        for (const [key, value] of Object.entries(miscData)) {
            if (key === changingObj || !miscData.manuallyChanged.includes(key) || key.includes("miscellaneous") || key.includes("credit")) continue;

            setValue(key, currency(value, { separator: ',', symbol: '' }).format());
        }
        setChangingObj(null);
    }, [miscData])

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data && miscData) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.sellersMiscellaneousChargesAndCredits)) {
                var sellerKey = 'seller' + capitalizeFirstLetter(key); 
                if (newArr.includes(sellerKey) && !key.includes("miscellaneous") && !key.includes("credit")) {
                    typeof value === 'number' ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);
                }
            }
            setValue("miscellaneousCost1", loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousCost1);
            if (currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee1).value < 0) { 
                setValue("miscellaneousFee1", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee1, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit1", true);
            } else {
                setValue("miscellaneousFee1", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee1, { separator: ',', symbol: '' }).format());
            }

            setValue("miscellaneousCost2", loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousCost2);
            if (currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee2).value < 0) { 
                setValue("miscellaneousFee2", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee2, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit2", true);
            } else {
                setValue("miscellaneousFee2", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee2, { separator: ',', symbol: '' }).format());
            }

            setValue("miscellaneousCost3", loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousCost3);
            if (currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee3).value < 0) { 
                setValue("miscellaneousFee3", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee3, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit3", true);
            } else {
                setValue("miscellaneousFee3", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee3, { separator: ',', symbol: '' }).format());
            }

            setValue("miscellaneousCost4", loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousCost4);
            if (currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee4).value < 0) { 
                setValue("miscellaneousFee4", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee4, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit4", true);
            } else {
                setValue("miscellaneousFee4", currency(loadedNetSheet.sellersMiscellaneousChargesAndCredits.miscellaneousFee4, { separator: ',', symbol: '' }).format());
            }
            setManuallyChangedInputs(newArr);
            setDefaults(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data || !miscData) return;
            setDefaults(manuallyChangedInputs);
        }
    }, [loadedNetSheet, data, miscData]);
        

    const setDefaults = (manuallyChanged) => { 
        if (!manuallyChanged.includes("sellerTaxProration") && !miscData.manuallyChanged.includes("taxProration")) { 
            setValue("taxProration", currency(data.sellersMiscellaneousChargesAndCredits.taxProration , { separator: ',', symbol: '' }).format());
        }
        if (!manuallyChanged.includes("sellerSecondHalfTaxes") && !miscData.manuallyChanged.includes("secondHalfTaxes")) { 
            setValue("secondHalfTaxes", currency(data.sellersMiscellaneousChargesAndCredits.secondHalfTaxes , { separator: ',', symbol: '' }).format());
        }   
        if (!manuallyChanged.includes("sellerAssistanceToBuyer") && !miscData.manuallyChanged.includes("assistanceToBuyer")) { 
            setValue("assistanceToBuyer", currency(data.sellersMiscellaneousChargesAndCredits.assistanceToBuyer , { separator: ',', symbol: '' }).format());
        }        
        if (!manuallyChanged.includes("sellerHomeOwnersAssociationCreditFromBuyer") && !miscData.manuallyChanged.includes("homeOwnersAssociationCreditFromBuyer")) { 
            setValue("homeOwnersAssociationCreditFromBuyer", currency(data.sellersMiscellaneousChargesAndCredits.homeOwnersAssociationCreditFromBuyer , { separator: ',', symbol: '' }).format());
        }
        
    }

    useEffect(() => {
        if (!watchMiscCost1) return;
        if (currency(watchMiscCost1).value < 0 && watchCredit1) return;
        if (currency(watchMiscCost1).value >= 0 && !watchCredit1) return;
        setValue('miscellaneousFee1', currency(watchMiscCost1, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchCredit1]);    
    
    useEffect(() => {
        if (!watchMiscCost2) return;
        if (currency(watchMiscCost2).value < 0 && watchCredit2) return;
        if (currency(watchMiscCost2).value >= 0 && !watchCredit2) return;
        setValue('miscellaneousFee2', currency(watchMiscCost2, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchCredit2]);    
    
    useEffect(() => {
        if (!watchMiscCost3) return;
        if (currency(watchMiscCost3).value < 0 && watchCredit3) return;
        if (currency(watchMiscCost3).value >= 0 && !watchCredit3) return;
        setValue('miscellaneousFee3', currency(watchMiscCost3, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchCredit3]);    
    
    useEffect(() => {
        if (!watchMiscCost4) return;
        if (currency(watchMiscCost4).value < 0 && watchCredit4) return;
        if (currency(watchMiscCost4).value >= 0 && !watchCredit4) return;
        setValue('miscellaneousFee4', currency(watchMiscCost4, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchCredit4]);

    useEffect(() => {
        const sum = currency(watchTaxProration, { separator: ',', symbol: '' })
            .add(watchSecondHalfPriorYearTaxes)
            .add(watchAssistanceToBuyer)
            .add(watchHomeWarranty)
            .add(watchPestInspection)
            .add(watchHomeownersAssociationFeeCredit)
            .add(watchMiscCost1)
            .add(watchMiscCost2)
            .add(watchMiscCost3)
            .add(watchMiscCost4)
            .format();
        setValue("sellerTotalMiscellaneous", sum);
        setMiscellaneousTotal(sum);

    }, [ watchTaxProration, watchSecondHalfPriorYearTaxes, watchAssistanceToBuyer, watchHomeWarranty, watchPestInspection, watchHomeownersAssociationFeeCredit,
         watchMiscCost1, watchMiscCost2, watchMiscCost3, watchMiscCost4, watchCredit1, watchCredit2, watchCredit3, watchCredit4]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setSellersMiscellaneous({formVals, manuallyChangedInputs});
    };

    const setManuallyChanged = (e) => {
        var sellerKey = 'seller' + capitalizeFirstLetter(e.target.id);
        for (const [key, value] of Object.entries(data.sellersMiscellaneousChargesAndCredits)) {
            if (key === e.target.id
                && currency(value, { separator: ',', symbol: '' }).format() !== e.target.value
                && !manuallyChangedInputs.includes(sellerKey)) {
                setManuallyChangedInputs([...manuallyChangedInputs, sellerKey])
            }
        }
    }

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideMiscellaneous" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Miscellaneous Charges And Credits</div>
                    <div className="col text-end">Total: {currency(watchSellerTotalMiscellaneousInput, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onBlur={e => formatInput(e)} onFocus={(e) => clearZero(e)} onChange={e => {setChangingObj(e.target.id); setMiscOpposites(e.target.id, e.target.value); setManuallyChanged(e) } }>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideMiscellaneous">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className=" col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="taxProration">
                                        *Tax Proration
                                    </label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="taxProration" type="text" defaultValue="0.00" maxLength="8" id="taxProration" className="form-control clear-zero currency" {...register("taxProration")}  />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="secondHalfTaxes">2nd Half {(data.netSheetFormData.propertyCounty.stateAbbr === 'ID'|| data.netSheetFormData.propertyCounty.stateAbbr === 'MT') && 'Prior Year '}Taxes*:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                    <input name="secondHalfTaxes" type="text" defaultValue="0.00" maxLength="8" id="secondHalfTaxes" className="form-control clear-zero currency" {...register("secondHalfTaxes")} />
                                </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className=" col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="assistanceToBuyer">Assistance To Buyer:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                    <input name="assistanceToBuyer" type="text" defaultValue="0.00" maxLength="8" id="assistanceToBuyer" className="form-control clear-zero currency" {...register("assistanceToBuyer")} />
                                </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className=" col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="homeWarranty">Home Warranty:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                    <input name="homeWarranty" type="text" defaultValue="0.00" maxLength="8" id="homeWarranty" className="form-control clear-zero currency" {...register("homeWarranty")} />
                                </div>
                                </div>
                            </div>
                            <div className={data.netSheetFormData.loanType === 'VA' ? 'row' : 'd-none'}>
                                <div className=" col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="pestInspection">Pest Inspection:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                    <input name="pestInspection" type="text" defaultValue="0.00" maxLength="8" id="pestInspection" className="form-control clear-zero currency" {...register("pestInspection")} />
                                </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="homeOwnersAssociationCreditFromBuyer">Homeowners Association Fee (credit from buyer):</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                    <input name="homeOwnersAssociationCreditFromBuyer" type="text" defaultValue="0.00" maxLength="8" id="homeOwnersAssociationCreditFromBuyer" className="form-control clear-zero currency" {...register("homeOwnersAssociationCreditFromBuyer")}  />
                                </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="d-block d-sm-none text-danger col">Enter negative numbers for credits.</div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-3 form-label mt-0">
                                            <input name="miscellaneousCost1" type="text" defaultValue="Misc Cost" id="miscellaneousCost1" className="form-control" {...register("miscellaneousCost1")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousFee1" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousFee1" className="form-control clear-zero currency" {...register("miscellaneousFee1")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit1" {...register("credit1")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-3 form-label mt-0">
                                            <input name="miscellaneousCost2" type="text" defaultValue="Misc Cost" id="miscellaneousCost2" className="form-control" {...register("miscellaneousCost2")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousFee2" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousFee2" className="form-control clear-zero currency" {...register("miscellaneousFee2")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit2" {...register("credit2")}/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-3 form-label mt-0">
                                            <input name="miscellaneousCost3" type="text" id="miscellaneousCost3" className="form-control" defaultValue="Misc Cost" {...register("miscellaneousCost3")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousFee3" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousFee3" className="form-control clear-zero currency" {...register("miscellaneousFee3")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit3" {...register("credit3")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-3 form-label mt-0">
                                            <input name="miscellaneousCost4" type="text" id="miscellaneousCost4" className="form-control" defaultValue="Misc Cost" {...register("miscellaneousCost4")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousFee4" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousFee4" className="form-control clear-zero currency" {...register("miscellaneousFee4")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit4" {...register("credit4")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellerTotalMiscellaneous">Total Misc Charges & Credits:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="sellerTotalMiscellaneous" type="text" defaultValue="0.00" readOnly="readonly" id="sellerTotalMiscellaneous" tabIndex="-1" className="form-control" {...register("sellerTotalMiscellaneous")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideMiscellaneous;