import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';

const BuyerSideMiscellaneous = ({data, setMiscellaneousTotal, className, saving, setBuyersMiscellaneous, setMiscOpposites, miscData, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */

    const watchHoaFeeCreditToSellerInput = watch("hoaFeeCreditToSeller");
    const watchHoaFeeInput = watch("hoaFee");
    const watchHoaSetupFeeInput = watch("hoaSetupFee");
    const watchHomeWarrantyInput = watch("homeWarranty");
    const watchAssistanceInput = watch("assistanceCredit");
    const watchTaxesFromSellerInput = watch("propertyTaxFromSellerCredit");
    const watchTaxesFromSeller2ndHalfInput = watch("secondHalfTaxesCredit");
    const watchMiscCost1 = watch("miscellaneousCost1");
    const watchMiscCost2 = watch("miscellaneousCost2");
    const watchMiscCost3 = watch("miscellaneousCost3");
    const watchcredit1 = watch("credit1");    
    const watchcredit2 = watch("credit2");
    const watchcredit3 = watch("credit3");
    const watchBuyerTotalMiscellaneousInput = watch("buyerTotalMiscellaneous");
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [changingObj, setChangingObj] = useState(null);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!miscData || (loadedNetSheet && !alreadyLoadedNetSheet)) return;
        for (const [key, value] of Object.entries(miscData)) {
            if (key === changingObj || !miscData.manuallyChanged.includes(key) || key.includes("miscellaneous") || key.includes("credit")) continue;
            setValue(key, currency(value, { separator: ',', symbol: '' }).format());       
        }
        setChangingObj(null);
    }, [miscData])

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data && miscData) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.buyersMiscellaneousChargesAndCredits)) {
                if (newArr.includes(key) && !key.includes("miscellaneous") && !key.includes("credit")) {

                    typeof value === 'number' ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);
                }
            }

            setValue("miscellaneousCostDescription1", loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription1);
            if (currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost1).value < 0) { 
                setValue("miscellaneousCost1", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost1, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit1", true);
            } else {
                setValue("miscellaneousCost1", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost1, { separator: ',', symbol: '' }).format());
            }
            setValue("miscellaneousCostDescription2", loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription2);
            if (currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost2).value < 0) { 
                setValue("miscellaneousCost2", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost2, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit2", true);
            } else {
                setValue("miscellaneousCost2", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost2, { separator: ',', symbol: '' }).format());
            }

            setValue("miscellaneousCostDescription3", loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCostDescription3);
            if (currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost3).value < 0) { 
                setValue("miscellaneousCost3", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost3, { separator: ',', symbol: '' }).multiply(-1).format());
                setValue("credit3", true);
            } else {
                setValue("miscellaneousCost3", currency(loadedNetSheet.buyersMiscellaneousChargesAndCredits.miscellaneousCost3, { separator: ',', symbol: '' }).format());
            }
            setManuallyChangedInputs(newArr);
            setDefaults(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data || !miscData) return;
            setDefaults(manuallyChangedInputs);
        }
    }, [loadedNetSheet, data, miscData]);

    const setDefaults = (manuallyChanged) => { 
        if (!manuallyChanged.includes("propertyTaxFromSellerCredit") && !miscData.manuallyChanged.includes("propertyTaxFromSellerCredit")) {
            setValue("propertyTaxFromSellerCredit", currency(data.buyersMiscellaneousChargesAndCredits.propertyTaxFromSellerCredit, { separator: ',', symbol: '' }).format());
        }
        if (!manuallyChanged.includes("secondHalfTaxesCredit") && !miscData.manuallyChanged.includes("secondHalfTaxesCredit")) { 
            setValue("secondHalfTaxesCredit", currency(data.buyersMiscellaneousChargesAndCredits.secondHalfTaxesCredit, { separator: ',', symbol: '' }).format());
        }
    }

    useEffect(() => {
        if (!watchMiscCost1) return;
        if (currency(watchMiscCost1).value < 0 && watchcredit1) return;
        if (currency(watchMiscCost1).value >= 0 && !watchcredit1) return;
        setValue('miscellaneousCost1', currency(watchMiscCost1, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchcredit1]);    
    
    useEffect(() => {
        if (!watchMiscCost2) return;
        if (currency(watchMiscCost2).value < 0 && watchcredit2) return;
        if (currency(watchMiscCost2).value >= 0 && !watchcredit2) return;
        setValue('miscellaneousCost2', currency(watchMiscCost2, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchcredit2]);    
    
    useEffect(() => {
        if (!watchMiscCost3) return;
        if (currency(watchMiscCost3).value < 0 && watchcredit3) return;
        if (currency(watchMiscCost3).value >= 0 && !watchcredit3) return;
        setValue('miscellaneousCost3', currency(watchMiscCost3, { separator: ',', symbol: '' }).multiply(-1).format())
    }, [watchcredit3]);    
    

    useEffect(() => {
        const sum = currency(watchHoaFeeCreditToSellerInput, { separator: ',', symbol: '' })
            .add(watchHoaFeeInput)
            .add(watchHoaSetupFeeInput)
            .add(watchHomeWarrantyInput)
            .add(watchAssistanceInput)
            .add(watchTaxesFromSellerInput)
            .add(watchTaxesFromSeller2ndHalfInput)
            .add(watchMiscCost1)
            .add(watchMiscCost2)
            .add(watchMiscCost3)
            .format();
        setValue("buyerTotalMiscellaneous", sum);
        setMiscellaneousTotal(sum);

    }, [ watchHoaFeeCreditToSellerInput, watchHoaFeeInput, watchHoaSetupFeeInput, watchHomeWarrantyInput, 
        watchAssistanceInput, watchTaxesFromSellerInput, watchTaxesFromSeller2ndHalfInput, watchMiscCost1, watchMiscCost2, watchMiscCost3, 
        watchcredit1, watchcredit3, watchcredit3]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setBuyersMiscellaneous({formVals, manuallyChangedInputs});
    };

    const setManuallyChanged = (e) => {
        for (const [key, value] of Object.entries(data.buyersMiscellaneousChargesAndCredits)) {
            if (key === e.target.id
                && currency(value, { separator: ',', symbol: '' }).format() !== e.target.value
                && !manuallyChangedInputs.includes(e.target.id)) {
                setManuallyChangedInputs([...manuallyChangedInputs, e.target.id])
            }
        }
    }

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseBuyerSideMiscellaneous" aria-expanded="false" aria-controls="collapseBuyerSideCommonData">
                    <div className="col fw-bold">Miscellaneous Charges And Credits</div>
                    <div className="col text-end">Total: <span className="no-localization">{currency(watchBuyerTotalMiscellaneousInput, { separator: ',', symbol: '$' }).format()}</span></div>
                </div>
                <form onBlur={e => formatInput(e)} onFocus={(e) => clearZero(e)} onChange={e => { setChangingObj(e.target.id); setMiscOpposites(e.target.id, e.target.value); setManuallyChanged(e) } }>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseBuyerSideMiscellaneous">
                        <div className="accordion-body">
                            <div className="row row-cols-1 row-cols-sm-2">
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className=" col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="hoaFeeCreditToSeller">
                                                HOA Fee<br/>
                                                (credit to seller):
                                            </label>
                                        </div>
                                        <div className="col-12 col-sm-7 no-localization">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="hoaFeeCreditToSeller" type="text" defaultValue="0.00" maxLength="8" id="hoaFeeCreditToSeller" className="form-control clear-zero currency" {...register("hoaFeeCreditToSeller")}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="hoaFee">HOA Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7 no-localization">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="hoaFee" type="text" defaultValue="0.00" maxLength="8" id="hoaFee" className="form-control clear-zero currency" {...register("hoaFee")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className=" col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="hoaSetupFee">HOA Setup Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7 no-localization">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="hoaSetupFee" type="text" defaultValue="0.00" maxLength="8" id="hoaSetupFee" className="form-control clear-zero currency" {...register("hoaSetupFee")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className=" col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="homeWarranty">Home Warranty:</label>
                                        </div>
                                        <div className="col-12 col-sm-7 no-localization">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="homeWarranty" type="text" defaultValue="0.00" maxLength="8" id="homeWarranty" className="form-control clear-zero currency" {...register("homeWarranty")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className=" col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="assistanceCredit">Assistance (CREDIT):</label>
                                        </div>
                                        <div className="col-12 col-sm-7 no-localization">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="assistanceCredit" type="text" defaultValue="0.00" maxLength="8" id="assistanceCredit" className="form-control clear-zero currency" {...register("assistanceCredit")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col no-localization">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="propertyTaxFromSellerCredit">Property Taxes{currency(watchTaxesFromSellerInput).value < 0 ? ' From Seller (CREDIT)' : ''}:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="propertyTaxFromSellerCredit" type="text" defaultValue="0.00" maxLength="8" id="propertyTaxFromSellerCredit" className="form-control clear-zero currency" {...register("propertyTaxFromSellerCredit")} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1 no-localization">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="secondHalfTaxesCredit">2nd Half{(data.netSheetFormData.propertyCounty.stateAbbr === 'ID' || data.netSheetFormData.propertyCounty.stateAbbr === 'MT') ? ' Prior Year' : ''} Taxes{currency(watchTaxesFromSeller2ndHalfInput).value < 0 ? ' (CREDIT)' : ''}:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="secondHalfTaxesCredit" type="text" defaultValue="0.00" maxLength="8" id="secondHalfTaxesCredit" className="form-control clear-zero currency" {...register("secondHalfTaxesCredit")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="d-block d-sm-none text-danger col">Enter negative numbers for credits.</div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-3 form-label fw-bold mt-0">
                                            <input name="miscellaneousCostDescription1" type="text" defaultValue="Earnest Money" id="miscellaneousCostDescription1" className="form-control" {...register("miscellaneousCostDescription1")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousCost1" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousCost1" className="form-control clear-zero currency" {...register("miscellaneousCost1")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit1" {...register("credit1")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-3 form-label fw-bold mt-0">
                                            <input name="miscellaneousCostDescription2" type="text" defaultValue="Misc Cost" id="miscellaneousCostDescription2" className="form-control" {...register("miscellaneousCostDescription2")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousCost2" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousCost2" className="form-control clear-zero currency" {...register("miscellaneousCost2")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit2" {...register("credit2")}/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            <div className="row pt-1">
                                <div className="col-12">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-3 form-label fw-bold mt-0">
                                            <input name="miscellaneousCostDescription3" type="text" id="miscellaneousCostDescription3" className="form-control" defaultValue="Misc Cost" {...register("miscellaneousCostDescription3")}/>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="miscellaneousCost3" type="text" defaultValue="0.00" maxLength="8" id="miscellaneousCost3" className="form-control clear-zero currency" {...register("miscellaneousCost3")}/>
                                        </div>
                                            </div>
                                        <div className="col-2 d-none d-sm-block">
                                            <span className="form-label fw-bold">Credit? </span>
                                            <input type="checkbox" id="credit3" {...register("credit3")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="buyerTotalMiscellaneous">Total Misc Charges & Credits:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="buyerTotalMiscellaneous" type="text" defaultValue="0.00" readOnly="readonly" id="buyerTotalMiscellaneous" tabIndex="-1" className="form-control" {...register("buyerTotalMiscellaneous")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default BuyerSideMiscellaneous;