import React, { useContext, Fragment } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { Link } from 'react-router-dom';
import './quick-tools-nav.scss';
import { FilePath } from '../../services/utilities';

const QuickToolsNav = ({ children }) => {
    const { brandTools } = useContext(BrandContext);
    const { activeRegion } = useContext(UserContext);
    
    return (
        <>
            {brandTools && brandTools.length > 0 && <>
                <div className="row">                
                    <div className="col col-3 me-2 d-none d-lg-block d-print-none">
                        <div className="card bg-light p-lg-2 p-xl-4 quick-tools">
                            <div className="h4">Quick Tools</div>
                            {brandTools.map((tool, index) => {
                                if ((!activeRegion) || (activeRegion && tool.name !== "Foreclosure Search") || (tool.name === "Foreclosure Search" && activeRegion && activeRegion.regionId !== 6)) {
                                    return <Fragment key={index}>
                                        <div className="row">
                                            <div className="col-2 pe-lg-3 pb-lg-2 pe-xl-4"><img src={FilePath('/images' + tool.image)} className="tool-icon" alt={tool.name + " icon"} /></div>
                                            <div className="col-10 pe-lg-2 pb-lg-2 ps-xl-4"><Link to={tool.link}>{(tool.name === "Closing Cost Calculator") ? "Closing Cost Calc" : tool.name}</Link></div>
                                        </div>
                                    </Fragment>
                                }
                                else { return <span key={index}></span> }
                            })}

                        </div>
                    </div>
                    <div className="col">
                        {children}
                    </div>
                </div> </>
                }
        </>
    );
}

export default QuickToolsNav;